import { Map, Placemark, Polyline } from '@pbe/react-yandex-maps';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { TClusterData } from 'shared/types/location.types';
import ymaps from 'yandex-maps';

interface IYmapProps {
  pointsMap: TClusterData[];
  isActiveMap?: boolean;
  children?: ReactNode;
}

export const YMap: FC<IYmapProps> = ({ pointsMap, isActiveMap, children }) => {
  const mapRef = useRef<ymaps.Map | undefined>();
  const getCenter = () => {
    let sumPointsLat = 0;
    let sumPointsLng = 0;

    pointsMap.map((point) => {
      sumPointsLat = sumPointsLat + point.coords[0];
      sumPointsLng = sumPointsLng + point.coords[1];
    });
    sumPointsLat = sumPointsLat / pointsMap.length;
    sumPointsLng = sumPointsLng / pointsMap.length;
    return [sumPointsLat, sumPointsLng];
  };

  useEffect(() => {
    if (isActiveMap) mapRef.current?.behaviors.enable(['drag', 'scrollZoom']);
    else mapRef.current?.behaviors.disable(['drag', 'scrollZoom']);
  }, [isActiveMap]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Map
        defaultState={{ center: getCenter(), zoom: 8, type: 'yandex#hybrid', behaviors: [] }}
        id={'osm-map'}
        style={{ width: '100%', height: '100%' }}
        instanceRef={mapRef}
        options={{
          maxZoom: 17,
          minZoom: 7,
        }}
      >
        {pointsMap && pointsMap.map((point) => <Placemark geometry={[point.coords[0], point.coords[1]]} />)}
        <Polyline
          geometry={pointsMap.map((point) => point.coords)}
          options={{
            strokeColor: '#fff',
            strokeWidth: 4,
            strokeOpacity: 1,
            strokeStyle: 'dashdot',
          }}
        />
        {children}
      </Map>
    </div>
  );
};
