import { TFilterLocations, TLocation, TLocationSeason } from 'shared/types/location.types';
import { TFiltersTours, TTour } from 'shared/types/tours.types';
import isContainArray from './isContainArray';

const monthNames = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

export const getSeasonForFilter = (seasonsLocation: TLocationSeason | null) => {
  if (!seasonsLocation || !seasonsLocation.startMonth || !seasonsLocation.endMonth) {
    return monthNames; // Если данные отсутствуют, возвращаем все месяцы.
  }

  const { startMonth, endMonth } = seasonsLocation;

  if (startMonth <= endMonth) {
    return monthNames.slice(startMonth - 1, endMonth);
  }

  return [
    ...monthNames.slice(startMonth - 1), // Месяцы до конца года.
    ...monthNames.slice(0, endMonth)    // Месяцы с начала года.
  ];
};

export const filterForLocations = (item: TLocation, values: TFilterLocations) => {
  const seasonsLocation = getSeasonForFilter(item.season);

  if (values.types.length && !isContainArray(item.locationType, values.types)) return false;
  if (values.season.length && !isContainArray(seasonsLocation, values.season)) return false;
  if (values.territory.length && !values.territory.includes(item.cluster?.name || '')) return false;
  return !(values.transport.length && !isContainArray(item.availableTransfers, values.transport));

};

const getSeasonTourForFilter = (tour: TTour) => {
  const seasons: TLocationSeason = (tour.startMonth && tour.endMonth) ? {
    startMonth: tour.startMonth,
    endMonth: tour.endMonth,
  } : {
    startMonth: 1,
    endMonth: 12,
  };
  return getSeasonForFilter(seasons);
};

const getDurationForFilter = (values: TFiltersTours) => {
  const [minDays, maxDays] = values.duration.split(/[\s-]+/, 2);
  return { minDays, maxDays };
};

const checkTypeTour = (tour: TTour, filterTypes: string[]) => {
  const { tourTypeMulti, productType } = tour;
  return !filterTypes.length || isContainArray(tourTypeMulti, filterTypes) || filterTypes.includes(productType);
};

const checkLocationsTour = (tour: TTour, filterLocations: string[]) => {
  return !filterLocations.length || !!tour.locations?.find((location) => filterLocations.includes(location.name));
};

export const customFilterForTours = (tour: TTour, values: TFiltersTours) => {
  const { price, duration } = tour;
  const [minPrice, maxPrice] = values.price;
  const { minDays, maxDays } = getDurationForFilter(values);
  const seasonsTour = getSeasonTourForFilter(tour);
  if (values.duration !== 'Не выбрано' && !(duration >= Number(minDays) && duration <= Number(maxDays))) return false;
  if (!(price >= minPrice && price <= maxPrice)) return false;
  if (values.season.length && !isContainArray(seasonsTour, values.season)) return false;

  // Проверка по месяцам
  if (
    values.months.length &&
    !values.months.some((month) => (tour.months || []).includes(month))
  ) {
    return false;
  }

  if (values.types.length && values.locations.length) {
    return checkLocationsTour(tour, values.locations) || checkTypeTour(tour, values.types);
  } else if (values.types.length || values.locations.length) {
    return checkLocationsTour(tour, values.locations) && checkTypeTour(tour, values.types);
  }
  return true;
};
