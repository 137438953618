import { api as apiNoAuth } from 'http/commonApi';
import { api as authApi } from 'http/index';
import { TLocation } from 'shared/types/location.types';
import { TTour } from 'shared/types/tours.types';
import { TFiltersTours } from 'shared/types/tours.types';;

export default class TourService {
  static async getLocations() {
    return await apiNoAuth().get<TLocation[]>('/locations');
  }

  static async getTours() {
    console.log("Формируемый запрос: /tours/published"); // Для отладки
    return await apiNoAuth().get<TTour[]>('/tours/published');
  }
  static async getUserTour() {
    return authApi().get<TTour[]>(`/tours/my`);
  }
  static async getToursById(id: string) {
    return await apiNoAuth().get<TTour>(`/tours/${id}`);
  }
  static async createDraft(sendData: TTour) {
    return await authApi().post<TTour>(`/tours/draft`, sendData);
  }
  static async updateDraft(sendData: TTour) {
    return await authApi().put<TTour>(`/tours/draft/${sendData.id}`, sendData);
  }
  static async getDraftById(id: number) {
    return await apiNoAuth().get<TTour>(`/tours/draft/${id}`);
  }

  // static async getFilteredTours(filters: Record<string, any>) {
  //   const params = new URLSearchParams();
  //   if (filters.months && filters.months.length > 0) {
  //     filters.months.forEach((month: number) => params.append('month.equals', month.toString()));
  //   }
  //   console.log("Параметры " + params)
  //   // Отправляем GET-запрос с параметрами
  //   return await apiNoAuth().get<TTour[]>('/tours', { params });
  // }
}
